import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/mlada-pobuda/mladapobuda_img1.png'
import img2 from '../../assets/images/mlada-pobuda/mladapobuda_img2.gif'
import img3 from '../../assets/images/mlada-pobuda/mladapobuda_img3.gif'
import img4 from '../../assets/images/mlada-pobuda/mladapobuda_img4.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterGrid,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineM,
  TitleL,
  TitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Oglaševanje na družbenih omrežjih',
    'Logotip',
    'Grafično oblikovanje',
    'Video produkcija',
    'Oglaševanje na družbenih omrežjih',
    'Logotip',
    'Grafično oblikovanje',
    'Video produkcija',
    'Oglaševanje na družbenih omrežjih',
    'Logotip',
    'Grafično oblikovanje',
    'Video produkcija',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Mlada Pobuda"
        description="Občina Tolmin je v letu 2020 del proračunskih sredstev prvič namenila participatornemu proračunu. 20.000€ so namenili mladinskim projektom, ki jih lahko predlagajo in zanje glasujejo le mladi iz tolminske občine."
        image={img1}
      />
      <Header
        logoBackground={colors.blue}
        logoBorder={colors.blue}
        linkText={colors.blue}
        menuLines={colors.blue}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Mlada Pobuda" />
          </div>
          <Square background={colors.yellow} className="title">
            <ParagraphM color={colors.white}>Občina Tolmin</ParagraphM>
            <HeadlineM color={colors.blue}>Mlada Pobuda</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.blue}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              <b>Mladim starih med 15 in 30 let</b> predstaviti model participatornega proračuna in
              jih spodbuditi k vključitvi v projekt.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Občina Tolmin je v letu 2020 del proračunskih sredstev prvič namenila participatornemu
              proračunu. 20.000€ so namenili <b>mladinskim projektom</b>, ki jih lahko predlagajo in
              zanje glasujejo le mladi iz tolminske občine.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding">
            <Content>
              {/* Example Title */}
              <h2>Celostna grafična podoba projekta</h2>

              {/* Example Paragraph */}
              <p>
                Za naročnika smo oblikovali celostno grafično podobo Mlade Pobude. Uporabili smo
                barve iz grba Občine Tolmin v bolj mladostnih odtenkih. Logotip ponazarja {''}
                <b>vzpodbudo, pobudo mladih.</b>
              </p>

              {/* Example Image */}
              <div className="align-center">
                <img
                  src={img2}
                  alt="Mlada Pobuda"
                  style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}
                />
              </div>

              {/* Example Title */}
              <h2>Promocijski videi</h2>

              {/* Example Paragraph */}
              <p>
                Pripravili smo tudi več promocijskih videov. Prvi, daljši video je bil namenjen
                informiranju o Mladi Pobudi, v njem pa so nastopali mladi iz delovne skupine. Video
                na humoren in sproščen način predstavi vse ključne lastnosti projekta, da je
                gledalcu po ogledu tega 1-minutnega videa jasno zakaj gre.
              </p>
              <p>
                Za tri krajše videe, smo poiskali <b>ambasadorje iz ciljne skupine</b>. Mladi so
                pozvali svoje sovrstnike, da oddajo pobude za mladinske projekte. Na ta način smo
                projekt dodatno približali mladim, ki so v promocijskih videih prepoznali svoje
                prijatelje in znance
              </p>

              {/* Example Video */}
              <iframe
                src="https://www.youtube.com/embed/4LnPccbuEv8"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* Example Image */}
              <img src={img3} alt="Mlada Pobuda" />

              {/* Example Title */}
              <h2>Spletna stran</h2>

              {/* Example Paragraph */}
              <p>
                Vzpostavili smo spletno stran, ki je bila namenjena izključno informiranju o
                projektu Mlada Pobuda. Preko spletne strani je bilo možno prebrati vse o Mladi
                Pobudi, oddati pobude in se vpisati v volilni imenik.
              </p>

              {/* Example Image */}
              <img src={img4} alt="Mlada Pobuda" />

              {/* Example Title */}
              <h2>Rezultati</h2>

              {/* Example Paragraph */}
              <p>
                Prejeli smo kar 42 pobud, kar je ena pobuda na 30 volilnih upravičencev. Volitev se
                je udeležilo 16,5% mladih iz Občine Tolmin, kar je nadpovprečna volilna udeležba v
                primerjavi z ostalimi primerljivimi projekti v drugih slovenskih občinah.
              </p>
            </Content>
          </SectionRichText>

          {/* CounterGrid Section - Conditionally visible */}
          <SectionCounterGrid>
            <div className="group">
              <Square background={colors.blue} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  36000+
                </TitleL>
                <ParagraphL color={colors.white}>ogledov video vsebin</ParagraphL>
              </Square>
              <Square background={colors.yellow} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  7000
                </TitleL>
                <ParagraphL color={colors.white}>ogledov spletne strani</ParagraphL>
              </Square>
            </div>
            <div className="group reverse">
              <Square background={colors.blue} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  42
                </TitleL>
                <ParagraphL color={colors.white}>prejetih pobud</ParagraphL>
              </Square>
              <Square background={colors.yellow} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  16,5%
                </TitleL>
                <ParagraphL color={colors.white}>volilna udeležba</ParagraphL>
              </Square>
            </div>
          </SectionCounterGrid>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/visit-good-place">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/gengigel">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
